.custom-checkbox-container {
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;

    .ant-checkbox-group {
        flex-direction: column;
        gap: 10px;
    }
}

.ant-upload-wrapper {
    display: flex;
}

.modal-comments.ant-modal {
    width: 100% !important;
    max-width: 800px !important;
}

.modal-export.ant-modal {
    width: 100% !important;
    max-width: 800px !important;
}

.ql-tooltip {
    display: none !important;
}